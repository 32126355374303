.elementor-message-danger{
  color: #EF4431;
  font-size: .8rem;
}
.form {
  direction: rtl;
}
.form .elementor-field-type-html{
  order: 1;
  text-align: center;
}

.form .iti *{
text-align: right;
direction: ltr;
font-size: 1rem;
}
.form .iti__country-list{
  position: relative !important;
  text-align: right important;
  direction: ltr;
}
.iti__country{
  font-size: 1rem;
  direction: ltr;
}
select {
  font-size: 1rem !important;
}
.has-errors .iti__flag-container {
  bottom: 24px;
}
@media only screen and (min-width: 2400px) {
  .iti__flag-container {
      top: 10px !important;
  }
}
[type="submit"]{
  width: 100%;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 0px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
